<template>
  <v-row>
    <v-col
      cols="12"
      class="py-0"
    >
      <div
        v-if="!specificClient"
        class="d-flex align-center justify-end"
      >
        <span class="input-label">
          Nowy klient
        </span>

        <v-switch
          :value="showNewClientForm"
          @change="changeNewClientFormStatus(!showNewClientForm)"
          :disabled="specificClient || showEditClientForm"
          hide-details
          class="ml-2 my-0 pa-0"
        />
      </div>
    </v-col>
    <v-col class="py-0">
      <v-row>
        <v-col
          cols="12"
          v-show="showNewClientForm"
          class="pt-0"
        >
          <ClientForm
            ref="newClientPartial"
            actions
            @submitClientForm="createClient"
            @close="changeNewClientFormStatus(false)"
          />
        </v-col>

        <v-col
          cols="12"
          v-if="showEditClientForm"
          class="pt-0"
        >
          <ClientForm
            ref="editClientPartial"
            :editing-client="chosenClient"
            actions
            @submitClientForm="updateClient"
            @close="changeEditClientFormStatus(false)"
          />
        </v-col>

        <template v-if="!showEditClientForm && !showNewClientForm">
          <v-col
            cols="12"
            class="pt-0"
            :class="{'pb-0' : showAddressForm || chosenClient}"
          >
            <v-menu
              v-model="showClientsList"
              v-bind="attributes.menu"
              min-width="468"
              max-width="auto"
              v-if="!specificClient"
            >
              <template #activator="{ on, attrs }">
                <v-text-field
                  ref="searchClientField"
                  v-model="query"
                  label="Szukaj klienta"
                  :disabled="showAddressForm"
                  :placeholder="getSearchShortkeysPlaceholder('klienta')"
                  outlined
                  clearable
                  autocomplete="off"
                  @input="searchClients"
                  @click:clear="clearClientsList"
                  v-bind="attrs"
                  v-on="on"
                  autofocus
                  v-shortkey="searchShortkeys"
                  @shortkey.native="focusSearchClientField"
                >
                  <template #prepend-inner>
                    <Icon
                      name="search"
                      size="small"
                      class="mr-2"
                    />
                  </template>
                </v-text-field>
              </template>

              <v-data-table
                :value="selectedClient"
                :headers="clientsTableHeaders"
                :items="parsedClientsList"
                :loading="searchProcessing"
                v-bind="tableAttrs"
                @click:row="selectClient"
              />
            </v-menu>
          </v-col>

          <template v-if="!showAddressForm">
            <v-col
              cols="12"
              class="pt-0"
            >
              <v-row
                class="pl-1 mb-3"
                :class="[specificClient ? 'mt-3' : 'mt-n1']"
              >
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <div class="input-label d-flex align-center text-14">
                    <v-input
                      :value="orderData.clientId"
                      :rules="[rules.required]"
                      class="validated-label"
                      hide-details
                    >
                      Informacje o kliencie:
                    </v-input>

                    <span
                      v-if="chosenClient && chosenClient.blocked"
                      class="red--text d-flex align-center ml-1"
                    >
                      <img
                        src="@/assets/icons/block-mark.svg"
                        class="mr-1"
                        alt="blocked"
                      > Klient zablokowany
                    </span>

                    <v-btn
                      v-if="!specificClient"
                      color="primary"
                      type="submit"
                      text
                      small
                      @click="changeEditClientFormStatus(true)"
                      :disabled="!orderData.clientId"
                    >
                      Edytuj klienta
                    </v-btn>
                  </div>
                  <div class="chosen-client-details">
                    {{ chosenClient ? chosenClient.name : '-' }}
                  </div>
                  <div class="chosen-client-details mt-1">
                    {{ chosenClient ? chosenClient.invoiceAddress : '-' }}
                  </div>
                </v-col>
                <v-col
                  v-for="param in clientBioParams"
                  :key="param.text"
                  :cols="param.cols"
                  class="pt-1 pb-0 pr-0 text-14"
                >
                  <span class="input-label mr-1">{{ param.text }}: </span>
                  <span class="chosen-client-details">{{ param.value || '-' }}</span>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              v-if="chosenClient && chosenClient.isMiddleman"
              class="d-flex align-center"
            >
              <span class="input-label mr-2">
                Zamów na innego klienta
              </span>
              <v-switch
                v-model="orderAsMiddleman"
                hide-details
                class="ml-2 my-0 pa-0"
                @change="setMiddleman"
              />
            </v-col>

            <template
              v-if="chosenClient && chosenClient.isMiddleman && orderAsMiddleman"
            >
              <v-col
                cols="12"
                class="pb-0"
              >
                <v-autocomplete
                  :value="orderData.clientId"
                  outlined
                  :items="middlemanClients"
                  :rules="[rules.required]"
                  label="Klient końcowy"
                  item-text="name"
                  item-value="id"
                  append-icon="mdi-chevron-down"
                  placeholder="Wybierz klienta końcowego"
                  dense
                  @input="emitUpdate('order.clientId', $event)"
                />
              </v-col>

              <v-col
                cols="12"
                class="d-flex align-center pt-0 mt-n2 mb-2"
              >
                <span class="input-label mr-2">
                  Faktura na pośrednika
                </span>
                <v-switch
                  v-model="invoiceForMiddleman"
                  :disabled="!orderData.clientId"
                  hide-details
                  class="ml-2 my-0 pa-0"
                  @change="setPayer"
                />
              </v-col>
            </template>

            <v-col cols="12">
              <v-autocomplete
                :value="orderData.addressId"
                outlined
                :items="addresses"
                :disabled="!orderData.clientId"
                :rules="orderData.clientId ? [rules.required] : []"
                label="Lokalizacja"
                hide-details
                :item-text="(item) => item.name || item.formattedAddress"
                item-value="id"
                append-icon="mdi-chevron-down"
                placeholder="Wybierz lokalizację zlecenia"
                dense
                @input="emitUpdate('order.addressId', $event)"
              />
            </v-col>

            <v-col class="pt-0 d-flex align-center justify-space-between">
              <div>
                <v-btn
                  color="primary"
                  text
                  small
                  :disabled="!orderData.clientId"
                  @click="$emit('addAddress')"
                >
                  Dodaj lokalizacje
                </v-btn>

                <v-btn
                  color="primary"
                  text
                  small
                  :disabled="!orderData.addressId"
                  @click="$emit('editAddress')"
                >
                  Edytuj lokalizacje
                </v-btn>
              </div>

              <div
                class="input-label"
                v-if="distance"
              >
                Odległość od bazy w linii prostej: {{ distance }} km
              </div>
            </v-col>

            <v-col
              cols="12"
              class="pt-1 pb-0 pr-0 text-14"
            >
              <span class="input-label mr-1">Numer telefonu lokalizacji: </span>
              <span class="chosen-client-details">{{ selectedAddress.phoneNumber || '-' }}</span>
            </v-col>

            <v-col
              cols="12"
              class="pt-1 pb-0 pr-0 text-14"
            >
              <span class="input-label mr-1">Notatka do lokalizacji: </span>
              <span class="chosen-client-details">{{ selectedAddress.notes || '-' }}</span>
            </v-col>

            <v-col cols="12">
              <v-tabs
                v-model="currentTab"
                class="pb-4"
              >
                <v-tab>
                  Proponowane zlecenia
                </v-tab>
                <v-tab :disabled="clientType === 'Osoba fizyczna'">
                  Produkty
                </v-tab>
                <v-tab>
                  Sprawdź cenę
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="currentTab">
                <v-tab-item>
                  <v-data-table
                    v-model="selectedOrder"
                    :headers="ordersTableHeaders"
                    :items="lastOrdersList"
                    :loading="searchProcessing"
                    v-bind="tableAttrs"
                    @click:row="populateOrder"
                  />
                </v-tab-item>
                <v-tab-item>
                  <v-data-table
                    v-model="selectedProduct"
                    :headers="productsTableHeaders"
                    :items="productsList"
                    :loading="searchProcessing"
                    v-bind="tableAttrs"
                    @click:row="populateProduct"
                  />
                </v-tab-item>
                <v-tab-item>
                  <div class="d-flex px-3 mt-6">
                    <v-select
                      outlined
                      label="Promień wyszukiwania"
                      v-model="priceCheckRadius"
                      :menu-props="{offsetY: true}"
                      :items="radiusOptions"
                      item-text="text"
                      item-value="value"
                      append-icon="mdi-chevron-down"
                      :rules="[rules.required]"
                    />
                    <v-btn
                      color="primary"
                      class="ml-4 base-hover"
                      :disabled="!isPriceCheckAllowed"
                      :loading="priceCheckProcessing"
                      @click="checkPrice"
                    >
                      Sprawdź
                    </v-btn>
                  </div>

                  <v-data-table
                    :headers="priceCheckTableHeaders"
                    :items="priceCheckOrders"
                    :loading="searchProcessing"
                    height="240"
                    v-bind="tableAttrs"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </template>
        </template>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ClientForm from './ClientForm'
import debounce from 'lodash/debounce'
import { mapState, mapActions } from 'vuex'
import rules from '../../../utils/validators'
import { formatValue } from '../../../utils/typesEnum'
import { updateOrderFields, updateProductFields, updatePaymentFields } from '../../../utils/fieldsUpdate'
import api from '../../../api/v1'
import attributes from '../../../const/datePickerAttrributes'
import { searchShortkeys, getSearchShortkeysPlaceholder } from '../../../const/shortKeys'

const clientsTableHeaders = [
  { text: 'Nazwa', value: 'name' },
  { text: 'NIP', value: 'invoiceNip' },
  { text: 'Nr BDO', value: 'bdoNumber' },
  { text: 'Adres', value: 'address' },
  { text: 'Numer Telefonu', value: 'phoneNumber' }
]
const ordersTableHeaders = [
  { text: 'Nr ref.', value: 'refNumber', sortable: false },
  { text: 'Typ kontenera', value: 'containerType.name', sortable: false },
  { text: 'Typ odpadu/kruszywa', value: 'debrisType.displayName', sortable: false },
  { text: 'Cena netto', value: 'parsedNetValue', sortable: false, width: 100 },
]
const productsTableHeaders = [
  { text: 'Typ kontenera', value: 'containerType.name', sortable: false },
  { text: 'Typ odpadu/kruszywa', value: 'debrisType.displayName', sortable: false },
  { text: 'Cena netto', value: 'parsedNetValue', sortable: false, width: 100 },
]
const priceCheckTableHeaders = [
  { text: 'Adres', value: 'formattedAddress' },
  { text: 'Cena netto', value: 'debrisNetValue', width: 120 },
  { text: 'Cena brutto', value: 'debrisGrossValue', width: 120 },
]
const radiusOptions = [
  { value: 500, text: '500 m' },
  { value: 1000, text: '1 km' },
  { value: 2000, text: '2 km' },
  { value: 3000, text: '3 km' },
  { value: 5000, text: '5 km' },
  { value: 10000, text: '10 km' },
  { value: 15000, text: '15 km' },
]

export default {
  components: {
    ClientForm
  },
  props: {
    showAddressForm: {
      type: Boolean,
      default: false
    },
    specificClient: {
      type: Boolean,
      default: false
    },
    addresses: {
      type: Array,
      default: () => []
    },
    orderData: {
      type: Object,
      required: true
    },
    courseType: {
      type: String,
      default: 'Podstawienie'
    },
    distance: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      searchShortkeys,
      attributes,
      rules,
      radiusOptions,
      priceCheckRadius: 2000,
      priceCheckTableHeaders,
      priceCheckProcessing: false,
      clientsTableHeaders,
      ordersTableHeaders,
      productsTableHeaders,
      priceCheckOrders: [],
      selectedClient: [],
      selectedProduct: [],
      selectedOrder: [],
      productsList: [],
      clientsList: [],
      lastOrdersList: [],
      query: '',
      chosenClient: null,
      showNewClientForm: false,
      showEditClientForm: false,
      searchProcessing: false,
      showClientsList: false,
      orderAsMiddleman: false,
      middlemanClients: [],
      invoiceForMiddleman: true,
      currentTab: 0,
      tableAttrs: {
        class: 'results-table',
        itemsPerPage: -1,
        hideDefaultFooter: true,
        fixedHeader: true,
        singleSelect: true,
      }
    }
  },
  computed: {
    ...mapState({
      department: state => state.core.department,
      singleClient: state => state.client.entity,
      isDialogActive: state => state.layout.dialog.active
    }),
    clientType () {
      return this.selectedClient?.[0]?.clientType
    },
    selectedAddress () {
      return this.addresses?.find(address => address.id === this.orderData.addressId) || {}
    },
    parsedClientsList () {
      return this.clientsList.map((client) => ({
        ...client,
        address: `${client.invoiceAddress}, ${client.invoiceCity}`
      }))
    },
    clientBioParams () {
      const { invoiceNip, phoneNumber, bdoNumber, bdoType, settlementType, notes } = this.chosenClient || {}
      return [
        { text: 'Telefon', value: phoneNumber, cols: 12 },
        { text: 'Typ dokumentu', value: settlementType, cols: 7 },
        { text: 'Numer NIP', value: invoiceNip, cols: 5 },
        { text: 'Logika BDO', value: bdoType, cols: 7 },
        { text: 'Numer BDO', value: bdoNumber, cols: 5 },
        { text: 'Notatka do klienta', value: notes, cols: 12 },
      ]
    },
    isPriceCheckAllowed () {
      const { lng, lat } = this.selectedAddress?.location || {}
      return this.department.id && this.priceCheckRadius && lat && lng
    }
  },
  watch: {
    showAddressForm (show) {
      if (show) this.query = this.chosenClient.name
    },
    'orderData.addressId' (id) {
      if (id) this.selectAddress(id)
    },
    clientType (type) {
      this.currentTab = Number(type === 'Firma') // 0 - orders, 1 - products
    }
  },
  mounted () {
    if (this.specificClient) {
      this.selectClient(this.singleClient)
      if (this.orderData.addressId) this.selectAddress(this.orderData.addressId)
    }
  },
  methods: {
    ...mapActions({
      addNewClient: 'clients/addNewItem',
      editClient: 'clients/editItem',
      getMiddlemen: 'middlemen/getItems',
      showSnackbar: 'snackbar/showSnackbar'
    }),
    getSearchShortkeysPlaceholder,
    changeNewClientFormStatus (status) {
      this.showNewClientForm = status
      this.showClientsList = false
      this.emitUpdate('isClientFormOpen', status)
      this.emitUpdate('showAddressForm', false)
    },
    changeEditClientFormStatus (status) {
      this.showEditClientForm = status
      this.showClientsList = false
    },
    setMiddleman(setAsMiddleman) {
      if (setAsMiddleman) {
        this.emitUpdate('order.clientId', null)
        this.emitUpdate('order.addressId', null)
        this.emitUpdate('order.payerId', this.chosenClient.id)
        this.emitUpdate('order.middlemanId', this.chosenClient.id)
        api.getItems({ tableName: 'clients', params: { filters: { middlemanId: this.chosenClient.id } } })
          .then(res => {
            this.middlemanClients = res.data.collection
          })
      } else {
        this.emitUpdate('order.clientId', this.chosenClient.id)
      }
    },
    setPayer(invoiceForMiddleman) {
      if (invoiceForMiddleman) {
        this.emitUpdate('order.payerId', this.chosenClient.id)
      } else {
        this.emitUpdate('order.payerId', this.orderData.clientId)
      }
    },
    selectClient (client, row) {
      if (row) row.select(true)
      if (client.declarationNotPresent) this.showSnackbar({ type: 'error', message: ['Ten klient nie posiada oświadczenia BDO'] })
      this.selectedClient = [client]
      this.chosenClient = client
      this.showClientsList = false
      this.emitUpdate('order.clientId', client.id)
      this.emitUpdate('order.payerId', client.id)
      this.emitUpdate('payment.settlementType', client.settlementType)
    },
    selectAddress (id) {
      this.emitUpdate('order.addressId', id)
      const { paymentType, paymentDueDays } = this.selectedAddress
      if (paymentType) this.emitUpdate('payment.paymentType', paymentType)
      if (paymentDueDays) this.emitUpdate('payment.paymentDueDays', paymentDueDays)
      this.productsList = this.selectedAddress.products?.map(product => ({
        ...product,
        parsedNetValue: formatValue(product.netValue, 'price')
      }))
      this.$nextTick(this.getLastOrders())
    },
    clearClientsList () {
      this.clientsList = []
    },
    clearClientData () {
      this.emitUpdate('order.clientId', null)
      this.emitUpdate('order.addressId', null)
    },
    searchClients: debounce(function () {
      this.searchProcessing = true
      this.showClientsList = true
      api.searchClients({
        departmentId: this.department.id,
        filters: {
          query: this.query
        }
      }).then((resp) => {
        this.clientsList = resp.data
        const isClientIncludedInClientsList = resp.data.map(client => client.id).includes(this.orderData.clientId)
        if (!isClientIncludedInClientsList) {
          this.clearClientData()
        }
      })
        .finally(() => {
          this.searchProcessing = false
        })
    }, 700),
    emitUpdate (key, value) {
      this.$emit('update', { key, value })
    },
    createClient (params) {
      this.addNewClient({ params })
        .then((resp) => {
          if (resp.isMiddleman) this.getMiddlemen()
          this.changeNewClientFormStatus(false)
          this.clientsList.push(resp)
          this.selectClient(resp)
          this.$nextTick(() => {
            this.$emit('addAddress')
          })
          const { email, phoneNumber, invoiceAddress, invoiceCity } = params
          this.emitUpdate('savedClientData', {
            email,
            phoneNumber,
            address: { formattedAddress: `${invoiceAddress || ''} ${invoiceAddress && invoiceCity ? ', ' : ''} ${invoiceCity || ''}` },
          })
        })
    },
    updateClient (updatedData) {
      const params = {
        tableName: 'clients',
        params: updatedData,
        id: this.chosenClient.id
      }
      this.editClient(params)
        .then((resp) => {
          if (params.params.isMiddleman) this.getMiddlemen()
          this.changeEditClientFormStatus(false)
          const index = this.clientsList.findIndex(client => client.id === this.chosenClient.id)
          this.clientsList[index] = resp
          this.$nextTick(this.selectClient(resp))
        })
    },
    getLastOrders () {
      const { id: clientId } = this.chosenClient
      const orderType = this.courseType === 'Podstawienie' ? 'standard' : 'transport'
      const { addressId } = this.orderData
      api.getClientLastDoneOrder(clientId, { addressId, orderType })
        .then((resp) => {
          this.lastOrdersList = resp.data.map(order => {
            const { debrisNetValue, transportNetValue } = order.payment
            return {
              ...order,
              parsedNetValue: formatValue(+debrisNetValue + +transportNetValue, 'price')
            }
          })
        })
    },
    async populateOrder (order) {
      const data = {
        ...order,
        vatPercentage: this.courseType === 'Podstawienie' ? 8 : 23
      }
      this.emitUpdate('isPriceCalculationAllowed', false)
      await updateOrderFields.call(this, data)
      updatePaymentFields.call(this, data)
      this.emitUpdate('isPriceCalculationAllowed', true)
    },
    async populateProduct (product) {
      const data = {
        ...product,
        vatPercentage: this.courseType === 'Podstawienie' ? 8 : 23
      }
      this.emitUpdate('isPriceCalculationAllowed', false)
      await updateProductFields.call(this, data)
      this.emitUpdate('isPriceCalculationAllowed', true)
    },
    focusSearchClientField () {
      this.$refs.searchClientField.focus()
    },
    checkPrice () {
      const { lng, lat } = this.selectedAddress?.location
      const { containerTypeId, debrisTypeId } = this.orderData

      const params = {
        departmentId: this.department.id,
        containerTypeIds: [containerTypeId],
        debrisTypeIds: [debrisTypeId],
        location: {
          lat,
          lng,
          radius: this.priceCheckRadius,
        }
      }
      if (this.isPriceCheckAllowed) {
        this.priceCheckProcessing = true
        api.getSimilarOrdersPrices(params)
          .then((resp) => {
            this.priceCheckOrders = resp.data.map((order) => ({
              formattedAddress: order.address.formattedAddress,
              transportNetValue: formatValue(order.payment.transportNetValue, 'price'),
              debrisNetValue: formatValue(order.payment.debrisNetValue, 'price'),
              debrisGrossValue: formatValue(order.payment.debrisGrossValue, 'price'),
            }))
            if (!resp.data.length) {
              this.showSnackbar({ type: 'error', message: ['Nie znaleziono powiązanych zleceń'] })
            }
          })
          .finally(() => {
            this.priceCheckProcessing = false
          })
      } else {
        this.showSnackbar({ type: 'error', message: ['Niepoprawna konfiguracja'] })
      }
    }
  }
}
</script>
